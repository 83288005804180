import { User } from "@/viewer/types/domain/user";
import { LaunchDarklyClient } from "./launchDarklyClient";
import { getLDUser } from "./launchDarklyHelper";
import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";
import docCookies from "./cookies";
import ApiConfig from "@/_lib/data/ApiConfig";
import ConfigHelper from "@/_lib/data/ConfigHelper";
import { loadReportData } from "./reportUtils";
import { StaghornReportResponse } from "@/viewer/types/domain/report";
import { ViewerApiResponse, ViewerContext } from "@/viewer/types/ViewerApiResponse";
import { DEFAULT_TIMEOUT } from "@/viewer/data/constants";
import * as Sentry from "@sentry/browser";
import { ViewTypes } from "@/viewer/types/domain/viewTypes";
import { createFetchTimeoutSignal } from "./fetch";
import { getBrowserTimezone } from "@/viewer/utils/timezones";

const TOKEN_TIMEOUT = 604800;

const timeout = ConfigHelper.timeout ? Number(ConfigHelper.timeout) : DEFAULT_TIMEOUT;

export const getTokenFromRefreshToken = async (): Promise<string | undefined> => {
  const host = ApiConfig.getPrefix();
  const form = new URLSearchParams();
  const refreshToken = docCookies.getItem("LB_TKN");
  form.append("refresh_token", refreshToken ?? "");
  form.append("client_id", ConfigHelper.getClientId());
  form.append("grant_type", "refresh_token");
  let token = null;
  const tokenFromRefreshToken = await fetch(`${host}/token`, {
    method: "POST",
    signal: createFetchTimeoutSignal(timeout),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: form,
  });

  if (tokenFromRefreshToken.ok) {
    token = await tokenFromRefreshToken.json();
    docCookies.setItem("LB_TKN", token.refresh_token, TOKEN_TIMEOUT, "/");
  }
  return token?.access_token;
};

export const fetchUser = async (): Promise<{ [key: string]: any }> => {
  const user = {} as User;
  const token = await getTokenFromRefreshToken();
  if (token) {
    const url = ApiConfig.getPrefix() + "/user/check_token";
    let user = {};
    const userResponse = await fetch(url, {
      method: "GET",
      signal: createFetchTimeoutSignal(timeout),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (userResponse.ok) {
      user = await userResponse.json();
      docCookies.setItem("USER", JSON.stringify(user), TOKEN_TIMEOUT, "/");
    }
    return user;
  }
  return user;
};
export const getToggleViewerApiFlag = async (user: User | null): Promise<boolean> => {
  let ldUserConfig = getLDUser(user);
  let flagResult = false;
  if (!ldUserConfig) {
    const user = await fetchUser();
    if (user) {
      ldUserConfig = getLDUser(user);
    }
  }
  if (ldUserConfig && !ldUserConfig.custom.customerId) {
    return false;
  }
  if (ldUserConfig) {
    const launchDarklyClient = new LaunchDarklyClient(ldUserConfig);

    await launchDarklyClient.client?.waitForInitialization();
    flagResult = launchDarklyClient.evaluateLDFlag(LDFlagEnums.Lv9385ToggleViewerApiLbapi, false) as boolean;
    return flagResult;
  }
  return false;
};

export const getLaunchDarklyClientForUser = async (user: User | null): Promise<LaunchDarklyClient | undefined> => {
  let ldUserConfig = getLDUser(user);
  let launchDarklyClient;
  if (!ldUserConfig) {
    const user = await fetchUser();
    if (user) {
      ldUserConfig = getLDUser(user);
    }
  }
  if (ldUserConfig) {
    launchDarklyClient = new LaunchDarklyClient(ldUserConfig);
    await launchDarklyClient.client?.waitForInitialization();
    return launchDarklyClient;
  }
};

export const getDesktopBrandingApiFlag = async (user: User | null): Promise<boolean> => {
  let flagResult = false;
  const launchDarklyClient = await getLaunchDarklyClientForUser(user);
  if (launchDarklyClient) {
    flagResult = launchDarklyClient.evaluateLDFlag(LDFlagEnums.Lv9217DesktopBranding, false) as boolean;
  }
  return flagResult;
};

export const parseQueryString = (
  queryString: string,
  viewId: number | null,
  viewTypes: ViewTypes
): { [key: string]: any } => {
  const params = new URLSearchParams(queryString);
  const result = {} as any;

  for (const [key, value] of params.entries()) {
    result[key] = value;
  }

  if (viewId) {
    result.view_id = viewId;
  }

  if (viewTypes.is_me) {
    result.is_me = viewTypes.is_me;
  }

  if (viewTypes.is_today) {
    result.is_today = viewTypes.is_today;
  }

  if (viewTypes.is_daily) {
    result.is_daily = viewTypes.is_daily;
  }

  if (!result.tz) {
    result.tz = getBrowserTimezone();
  }

  return result;
};

export const parseViewId = (queryString: string): number | null => {
  const match = queryString.match(/\/(\d+)\?/) || queryString.match(/\/(\d+)/);
  let viewId = null;
  if (match) {
    viewId = +match[1];
  }
  return viewId;
};

export const parseMeTodayDailyView = (queryString: string): ViewTypes => {
  const isMe = queryString.match(/\/(me)\?/) || queryString.match(/\/(me)/);
  const isToday = queryString.match(/\/(today)\?/) || queryString.match(/\/(today)/);
  const isDaily = queryString.match(/\/(daily)\?/) || queryString.match(/\/(daily)/);
  const viewTypes: ViewTypes = { is_me: false, is_daily: false, is_today: false };
  if (isMe) {
    viewTypes.is_me = true;
  }
  if (isToday) {
    viewTypes.is_today = true;
  }
  if (isDaily) {
    viewTypes.is_daily = true;
  }
  return viewTypes;
};

export const getUser = (): any => {
  const userCookie = docCookies.getItem("USER");
  let user = null;

  if (userCookie) {
    user = JSON.parse(userCookie);
  }
  return user;
};

export const parseDateTimeString = (dateString: string | undefined): string => {
  return dateString ?? "";
};

export const getReportData = async (body: ViewerApiResponse): Promise<StaghornReportResponse | undefined> => {
  const user = getUser();
  const ldUserConfig = getLDUser(user);
  const view = body?.view_context as ViewerContext;
  const viewTypes = parseMeTodayDailyView(window.location.href);
  const data = parseQueryString(window.location.search, view.view_id, viewTypes);
  const dt = parseDateTimeString(data?.dt);
  if (ldUserConfig) {
    const launchDarklyClient = new LaunchDarklyClient(ldUserConfig);
    await launchDarklyClient.client?.waitForInitialization();
    const reportData = await loadReportData(launchDarklyClient, view, user, user.tz, dt);
    if (reportData) {
      return reportData;
    }
  }
  return undefined;
};

export const stringifyViewerAPIResponse = (response: any): { [key: string]: string } => {
  const stringifyResponseValues: { [key: string]: string } = {};
  if (response) {
    Object.keys(response).forEach((key) => {
      if (key !== "view_context") {
        stringifyResponseValues[key] = JSON.stringify(response[key]);
      } else {
        stringifyResponseValues[key] = response[key];
      }
    });
  }
  return stringifyResponseValues;
};

export const fetchViewerAPILBAPIPayload = async (url: string): Promise<Response> => {
  let resp;
  const viewId = parseViewId(window.location.href);
  const viewTypes = parseMeTodayDailyView(window.location.href);
  const data = parseQueryString(window.location.search, viewId, viewTypes);
  const user = getUser();
  Sentry.setUser({
    id: user.user_id,
    username: user.user_name,
  });
  Sentry.setTag("customer_id", user.customer_id);
  Sentry.setTag("customer_name", user.customer_name);
  Sentry.setTag("personnel_id", user.emp_id);
  Sentry.setTag("is_admin", user.is_admin);
  Sentry.setTag("is_super_admin", user.is_super_admin);
  Sentry.setTag("view_id", viewId);
  Sentry.addBreadcrumb({
    category: "request",
    message: `new viewerapi request body`,
    level: Sentry.Severity.Info,
    data,
  });
  const token = await getTokenFromRefreshToken();
  if (token) {
    resp = await fetch(url, {
      method: "POST",
      signal: createFetchTimeoutSignal(timeout),
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } else {
    resp = new Response(JSON.stringify({ message: "Unauthorized" }), {
      status: 401,
      headers: { "Content-Type": "application/json" },
    });
  }
  return resp;
};
